import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactGA from "react-ga"

// Prepared HCaptcha inclusion to some extend, but didn't activate yet as need for it not clear.

// import HCaptcha from "@hcaptcha/react-hcaptcha"

// const processForm = form => {
//   const data = new FormData(form)
//   data.append("form-name", "newsletter")
//   fetch("/", {
//     method: "POST",
//     body: data,
//   })
//     .then(() => {
//       form.innerHTML = `<div class="form--success">Almost there! Check your inbox for a confirmation e-mail.</div>`
//     })
//     .catch(error => {
//       form.innerHTML = `<div class="form--error">Error: ${error}</div>`
//     })
// }

// const emailForm =
//   typeof document !== `undefined` && document.querySelector(".email-form")
// if (emailForm) {
//   emailForm.addEventListener("submit", e => {
//     e.preventDefault()
//     processForm(emailForm)
//   })
// }

export class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      honeypot: false,
    }
  }

  handleEmailChange = event => {
    this.setState({
      email: event.target.value,
    })
  }

  setHoneypot = event => {
    this.setState({
      honeypot: true,
    })
  }

  handleSubscribe = async event => {
    event.preventDefault()

    if (this.state.honeypot === true) {
      return null
    }

    const emailForm =
      typeof document !== `undefined` && document.querySelector(".email-form")
    const url = `/.netlify/functions/newsletter-subscribe?email=${this.state.email}`
    try {
      const response = await fetch(url)
      const data = await response.json()
      emailForm.innerHTML = `<div class="form--success shadow-md px-3 py-3 lg:px-8 lg:py-8 border-t-4 border-green-500 rounded-t-none rounded-md bg-white bg-opacity-95 text-cb-900 text-base lg:text-lg">
      Almost there! Please check your inbox for a confirmation email.
      </div>`
      ReactGA.event({
        category: "User",
        action: "Subscribed",
      })
      return data
    } catch (error) {
      emailForm.innerHTML = `<div class="form--error shadow-md px-3 py-3 lg:px-8 lg:py-8 border-t-4 border-red-500 rounded-t-none rounded-md bg-red-200 bg-opacity-95 text-cb-900 text-base lg:text-lg">Error: ${error}. Please contact me at hello@paigemcclanahan.com with this error message.</div>`
    }
  }

  render() {
    return (
      <div
        className={
          "flex items-center justify-center w-full" +
          (this.props.isInPost ? "" : " pt-8 lg:w-3/4")
        }
      >
        <form
          // class email-form important for querySelector
          className={
            "flex flex-col items-end justify-center email-form lg:flex-row 2xl:max-w-screen-lg 3xl:max-w-screen-xl" +
            (this.props.isInPost ? " w-full min-w-full" : " w-5/6 lg:w-3/4")
          }
          name="newsletter"
          method="POST"
          // data-netlify-recaptcha="true"
          // data-netlify="true"
          // data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubscribe}
        >
          <div hidden aria-hidden="true">
            <label>
              Don’t fill this out if you're human:
              <input
                name="name"
                autoComplete="off"
                type="name"
                placeholder="Your name here"
                value=""
                onChange={this.setHoneypot}
              />
            </label>
          </div>
          {/* <label htmlFor="" className="">
            Enter your Email Address */}
          <input
            className="w-full px-3 py-3 mb-3 text-base bg-gray-300 rounded-md shadow-inner lg:text-base lg:mr-3 lg:mb-0 focus:outline-none"
            autoFocus={this.props.autoFocus}
            name="email"
            type="email"
            aria-label="Enter your email address to subscribe to the newsletter"
            required
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
          {/* </label> */}
          {/* <HCaptcha
            className="w-full "
            sitekey="e897066e-b191-4dca-9574-7be099c320d3"
            size="invisible"
            onVerify={this.onVerifyCaptcha}
          /> */}
          <button
            className={
              "w-full py-3 text-base text-white transition-colors duration-500 rounded-md bg-cb-900 hover:bg-cb-800 lg:text-base" +
              (this.props.isInPost ? " lg:max-w-max lg:px-5" : " py-3 lg:w-1/3")
            }
            type="submit"
            aria-label="Newsletter subscribe button"
          >
            Subscribe
          </button>
        </form>
      </div>
    )
  }
}

Form.propTypes = {
  autoFocus: PropTypes.string,
  isInPost: PropTypes.bool,
}

export default Form
